import type { NextPage } from 'next'
import { Button } from 'plan-design-code-ui'

const HomePage: NextPage = () => {
  return (
    <>
      <h1>Plan, Design & Code</h1>
      <Button>This is a button</Button>
    </>
  )
}

export default HomePage
