const MATCH_EXTERNAL = /^((https?:\/\/|\/\/)|mailto|tel)/i

/**
 * Detects whether a given href is from an external source than the
 * current origin.
 */
const isExternalURL = (url: string): boolean | undefined => {
  const isProtocolLike = url.indexOf(':') > -1 || url.indexOf('//') > -1
  const matchesExternal = MATCH_EXTERNAL.test(url)
  return isProtocolLike && matchesExternal
}

export { isExternalURL }
