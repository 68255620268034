import { isExternalURL } from 'highway'
import NextLink from 'next/link'
import React, { ForwardedRef } from 'react'

import styles from './Button.module.css'

type ButtonCommonProps = {
  ariaLabel?: string
  children: React.ReactNode
  disabled?: boolean
  className?: string
}

type ButtonLinkProps = ButtonCommonProps & {
  href?: string
  scroll?: boolean
}

type ButtonClickProps = ButtonCommonProps & {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  form?: boolean | string
}

export type ButtonPrimitiveProps = ButtonLinkProps & ButtonClickProps

/* 
  In order to support both anchor tags and buttons as the output of this
  component, we're overloading the function declaration with props from
  both types.
  @see https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
 */
export function ButtonPrimitive(props: ButtonLinkProps, ref: ForwardedRef<HTMLElement>): JSX.Element
export function ButtonPrimitive(
  props: ButtonClickProps,
  ref: ForwardedRef<HTMLElement>,
): JSX.Element
export function ButtonPrimitive(
  props: Partial<ButtonPrimitiveProps>,
  ref: ForwardedRef<HTMLElement>,
): JSX.Element {
  const {
    href,
    onClick,
    form = false,
    disabled = false,
    scroll = true,
    children,
    ariaLabel,
    className,
    ...dataAttributes
  } = props

  if (href && typeof href === 'string') {
    if (disabled) {
      return (
        /* This is an edge case since anchor links cannot technically be disabled
          As discussed in https://github.com/superhi/bounties/pull/36
          @see https://www.scottohara.me/blog/2021/05/28/disabled-links.html
        */
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
          data-component-name="Button_Link"
          role="link"
          aria-disabled="true"
          className={className}
        >
          {children}
        </a>
      )
    }

    return isExternalURL(href) ? (
      <a
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        data-component-name="Button_Link"
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        aria-label={ariaLabel}
        className={className}
      >
        {children}
      </a>
    ) : (
      <NextLink
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        aria-label={ariaLabel}
        className={className}
        data-component-name="Button_Link"
        href={href}
        scroll={scroll}
      >
        {children}
      </NextLink>
    )
  }

  return (
    <button
      ref={ref as React.ForwardedRef<HTMLButtonElement>}
      data-component-name="Button"
      onClick={onClick}
      disabled={disabled}
      type={form ? 'submit' : 'button'}
      aria-label={ariaLabel}
      className={styles.default}
      {...dataAttributes}
    >
      {children}
    </button>
  )
}

export const Button = React.forwardRef(ButtonPrimitive)
